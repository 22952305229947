import React, { useState } from 'react';
import './Navbar.scss';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from "framer-motion";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'> 
        <h1 className='app__navbar-name'>BATKHISHIG CHOIDAGVA</h1>
      </div>
      <ul className='app__navbar-links'>
        {['home', 'about', 'work', 'contact', 'resume'].map((item) => (
          <li className='app__flex p-text' key={`link-${item}`}>
            {item === 'resume' ? (
              <a href="https://drive.google.com/file/d/1ieFZonM1YHfdMA2HAEzq4CDgQKSNbBRr/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                {item}
              </a>
            ) : (
              <a href={`#${item}`}>{item}</a>
            )}
          </li>
        ))}
      </ul>
      <div className='app__navbar-menu'>
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {['home', 'about', 'work', 'contact', 'resume'].map((item) => (
                <li key={item}>
                  {item === 'resume' ? (
                    <a
                      href="https://www.dropbox.com/s/your-resume-link.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => setToggle(false)}
                    >
                      {item}
                    </a>
                  ) : (
                    <a href={`#${item}`} onClick={() => setToggle(false)}>
                      {item}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
